/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
body {
    margin: 0px;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

input {
    padding: 20px;
    border: 2px solid white;
    border-radius: 40px;
}

input:focus {
    padding: 20px;
    border: 4px solid gray;
    border-radius: 40px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input {
    &:focus {
        outline: none !important;
    }
}
textarea {
    &:focus {
        outline: none !important;
    }
}

// Assigned,Appointments,Acquisition common designs
.col-design {
    background-color: white;
    margin: 5px 5px 5px 0px;
    border-radius: 15px;
    // margin-right: 15px;
    height: 275px;
    // overflow: auto;
    width: 93%;
}

.col-design::-webkit-scrollbar {
    display: none;
}

.col-design {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.col-design {
    &:hover,
    &:focus,
    &:active {
        border: 1px solid red;
    }
}

.border-rt {
    border-right: 1px solid #e6e6e7;
    margin-right: 8px;
    height: 275px;
}

.row-design {
    width: 100%;
    padding-top: 25px;
}
.cards-containor {
    justify-content: center;
}
.searchbox-containor {
    width: calc(90% + 22px);
}
.content-left {
    padding: 10px 0px 10px 15px;
    overflow: hidden;
    height: 275px;
}
.no-margin {
    margin: 0 !important;
}
.full-width {
    width: 100% !important;
}
.full-height {
    height: 100%;
}
.auto-height {
    height: auto !important;
}
.space-around {
    justify-content: space-around;
}
.content-right {
    margin-top: 20px;
}

.section-design {
    background: #f2f2f2;
    // width: 120%;
    // margin: -20px;
    min-height: 120%;
}

.img-style {
    height: 30px;
    margin-bottom: 40px;
}

.img-icon-left {
    height: 25px;
    margin-bottom: 20px;
}

.content-details {
    display: flex;
    flex-direction: row;
}

.icon-width {
    width: 35px;
    padding-top: 12px;
}

.mobile-style {
    font-size: 30px;
    color: #29abe2;
}

.name-style {
    color: #8bc53f;
    font-size: 26px;
    font-weight: 600;
    font-family: sans-serif;
    height: 45px;
    overflow: hidden;
}

.name-detail-align {
    width: 80%;
    padding-top: 8px;
}

.address-style {
    color: #999999;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: -0.5px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-type-align {
    padding-top: 12px;
}

.add-type-style {
    color: #999999;
    font-weight: 300;
    font-size: 20px;
}

.val-typ-style {
    font-size: 20px;
}
.df {
    display: flex;
    flex-wrap: wrap;
}
.card-style {
    width: 45%;
    min-width: 475px;
    margin: 8px;
}
.last-card-style:last-child {
    margin-right: auto;
    margin-left: 4.4%;
}
.align-icons {
    display: flex;
    justify-content: center;
}
.align-center {
    display: flex;
    justify-content: center;
}
.search-box,
.search-box:focus {
    padding: 7px;
    width: 100%;
    // margin: 8px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
}

.nofocus:focus {
    outline: none !important;
    border: 0 !important;
}

.ngx-spinner-overlay {
    top: 75px!important;
    left: 175px!important;
    width: 100%;
    height: 100%;
}

.donate-now {
    list-style-type: none;
    /* margin: 25px 0 0 0; */
    padding: 5px 0px 0px 0px;
  }

  .donate-now li {
    float: left;
    margin: 0px 10px 0 0;
    width: 275px;
    height: 40px;
    position: relative;
  }

  .donate-now label,
  .donate-now input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .donate-now input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
    display: none;
  }

  .donate-now input[type="radio"]:checked+label,
  .Checked+label {
    color: #231F20;
    border: 3px solid #8BC53F;
    padding-bottom: 25px;
    padding-top: 8px;
  }

  .donate-now label {
    cursor: pointer;
    z-index: 90;
    text-align: center;
    overflow: hidden;
    border: 3px solid #CCCCCC;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 25px;
  }

  .donate-now label:hover {
    background: #DDD;
  }
  .radio-btn-label {
      width: 100%;
      margin: 0 15px 0 0 !important;
  }
  .rbl2 {
      width: 47% !important;
  }
  .rbl1 {
      width: 47% !important;
  }
@media screen and (max-width: 1285px) {
    .last-card-style:last-child {
        margin-left: 8px !important;
        margin-right: 8px !important;
        margin: 8px !important;
    }
    .rbl2 {
        width: 47% !important;
    }
    .rbl1 {
        width: 45% !important;
    }
}
@media screen and (max-width: 1020px) {
    body {
        font-size: 12px !important;
    }

    // Assigned,Appointments,Acquisition common designs

    .row-design {
        width: 100%;
        padding: 20px 30px 0px 25px !important;
    }
    .col-design {
        height: 160px;
        // overflow: auto;
    }

    .section-design {
        background: #f2f2f2;
        // width: 120%;
        // margin: -25px;
        // height: auto;
        // min-height: 110%;
    }

    .img-style {
        height: 18px;
        margin-bottom: 20px;
    }

    .img-icon-left {
        height: 15px;
        margin-bottom: 10px;
    }

    .content-right {
        margin-top: 18px;
    }

    .content-left {
        height: 160px;
    }

    .icon-width {
        width: 20px;
        padding-top: 8px;
    }
    .mobile-style {
        font-size: 20px;
    }
    .name-detail-align {
        width: 80%;
        padding-top: 2px;
    }
    .name-style {
        height: 26px;
        font-size: 20px;
    }
    .address-style {
        font-size: 12px;
        height: 37px;
    }

    .add-type-align {
        padding-top: 9px;
    }

    .add-type-style {
        font-size: 12px;
    }

    .val-typ-style {
        font-size: 12px;
    }

    .align-icons {
        padding-left: 5px;
    }
    .search-box,
    .search-box:focus {
        padding: 7px;
        margin-bottom: 20px;
        // padding-left: -64px;
        // margin-left: -12px;
        border-radius: 5px;
    }

    .border-rt {
        height: 160px;
    }

    .ngx-spinner-overlay {
        top: 0!important;
        left: 0!important;
    }
}
@media screen and (max-width: 890px) {
    .cards-containor {
        justify-content: flex-start !important;
    }
}
@media screen and (max-width: 600px) {
    .radio-btn-label {
        position: unset !important;
        padding: 4px 0 4px 0 !important;
    }
}
@media screen and (max-width: 420px) {
    .card-style {
        width: 93%;
        min-width: 205px;
    }
    .date-style {
        font-size: 12px !important;
    }
    .name-style {
        height: 26px;
        font-size: 15px !important;
    }
    .searchbox-containor {
        margin: 8px;
        width: 93%;
        overflow: hidden;
    }
}
